import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedService } from './shared/services/shared.service';
import { CacheInterceptor, CleanObjectInterceptor, MaplixApp } from '@maplix/utils';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CookieService } from 'ngx-cookie-service';
import { UiModule } from '@maplix/ui';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { DirectivesModule } from '@maplix/directives';
import { environment } from '../environments/environment';
import { ServicesModule } from '@maplix/services';
import { ApiModule } from '@maplix/api';
import { NgxPermissionsModule } from 'ngx-permissions';
import { StravaPluginModule } from '@maplix/strava-plugin';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    HttpClientModule,
    LayoutsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
      useDefaultLang: true,
    }),
    BsDatepickerModule.forRoot(),
    UiModule,
    DirectivesModule.forRoot(environment),
    ServicesModule.forRoot(environment, MaplixApp.ENGAGE),
    ApiModule.forRoot(environment),
    NgxPermissionsModule.forRoot(),
    StravaPluginModule.forRoot(environment),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CleanObjectInterceptor, multi: true },
    SharedService,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
