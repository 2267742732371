import { MapCls } from './mapcls';
import { Heatmap } from 'ol/layer.js';
import { TranslateService } from '@ngx-translate/core';
import { Coordinate } from 'ol/coordinate';
import { MapBrowserEvent } from 'ol';
import { Cluster } from 'ol/source';
import { HttpClient } from '@angular/common/http';
import { MapType } from '@maplix/utils';

export class MapClsAnalytics extends MapCls {
  constructor(
    public target: string,
    protected readonly translate: TranslateService,
    protected readonly http: HttpClient,
    protected readonly environment: any,
    center?: Coordinate,
    zoom?: number,
    fullScreenBox: string = 'fullscreenBox',
    isStatic: boolean = false
  ) {
    super(target, translate, http, environment, center, zoom, isStatic);
    this.addFullScreenControl(fullScreenBox);

    this.map.on('click', (e) => this.onClickMap(e));

    this.map.on('pointermove', (e) => this.onHoverMap(e));

    this.mapType = MapType.MAP;
  }

  protected onHoverMap(e: MapBrowserEvent<UIEvent>) {
    const features = [];
    this.map.forEachFeatureAtPixel(
      e.pixel,
      (f, layer) => {
        if (layer.get('selectable') && layer.get('visible') && !layer.get('base') && !(layer instanceof Heatmap)) {
          features.push(f);
        }
      },
      { hitTolerance: 4 }
    );

    if (features.length) {
      document.getElementById(this.target).classList.add('clickable-map');
    } else {
      document.getElementById(this.target).classList.remove('clickable-map');
    }
  }
}
