export const environment = {
  env: 'PROD',
  cookieDomain: 'maplix.com',
  production: true,
  root: 'https://engage.maplix.com/',
  engage: 'https://engage.maplix.com/',
  account: 'https://account.maplix.com/',
  explore: 'https://explore.maplix.com/',
  api: 'https://api.maplix.com/',
  stitchId: 'maplix-account-prod-ukpqz',
  redirect: 'engage.maplix.com',
  geoserver: 'https://geo.maplix.com/geoserver/',
  geoserverDb: 'maplix-prod',
  mapboxToken: 'pk.eyJ1IjoiY2VkcmljdmVyc2x1eXMiLCJhIjoiY2s5azFwaTFvMDg5czNsbnNxY2ExbHgyeSJ9.W_Tk-JA9ujNEL55b_cf7Hw',
  plausible: {
    domain: 'engage.maplix.com',
  },
  strava: {
    clientId: '112538',
    clientSecret: '5e482cc6ba83e1dfa5da920a6e929dc97e5dc1eb',
  },
};
