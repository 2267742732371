import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthGuard } from '@maplix/guards';
import { StravaRedirectComponent } from '@maplix/strava-plugin';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
    data: {
      env: environment,
    },
  },
  {
    path: 'strava-redirect',
    component: StravaRedirectComponent,
  },
  {
    path: 'error',
    loadChildren: () => import('@maplix/error').then((m) => m.ErrorModule),
  },
  {
    path: ':lang',
    loadChildren: () => import('./survey-response/survey-response.module').then((m) => m.SurveyResponseModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
