import { ImageCls } from './imagecls';
import { Extent } from 'ol/extent';
import { TranslateService } from '@ngx-translate/core';
import { MapBrowserEvent } from 'ol';
import { HttpClient } from '@angular/common/http';
import { MapType } from '@maplix/utils';
import { Coordinate } from 'ol/coordinate';

export class ImageClsAnalytics extends ImageCls {
  constructor(
    target: string,
    translate: TranslateService,
    protected http: HttpClient,
    protected environment: any,
    protected image: string,
    protected imageExtent: Extent,
    center?: Coordinate,
    zoom?: number,
    fullScreenBox: string = 'fullscreenBox'
  ) {
    super(target, translate, http, environment, image, imageExtent, center, zoom);
    this.addFullScreenControl(fullScreenBox);

    this.map.on('click', (e) => this.onClickMap(e));

    this.map.on('pointermove', (e) => this.onHoverMap(e));

    this.mapType = MapType.IMAGE;
  }

  private onHoverMap(e: MapBrowserEvent<UIEvent>) {
    const features = [];
    this.map.forEachFeatureAtPixel(
      e.pixel,
      (f, layer) => {
        if (layer.get('selectable') && layer.get('visible') && !layer.get('base')) {
          features.push(f);
        }
      },
      { hitTolerance: 4 }
    );

    if (features.length) {
      document.getElementById(this.target).classList.add('clickable-map');
    } else {
      document.getElementById(this.target).classList.remove('clickable-map');
    }
  }

  public getImageProperties(): { image: string; imageExtent: Extent } {
    return {
      image: this.image,
      imageExtent: this.imageExtent,
    };
  }
}
