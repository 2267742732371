import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragulaModule } from 'ng2-dragula';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@maplix/ui';
import { DirectivesModule } from '@maplix/directives';
import { PipesModule } from '@maplix/pipes';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MapsModule } from '@maplix/maps';
import { environment } from '../../environments/environment';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CloudModule } from '@maplix/cloud';

@NgModule({
  imports: [
    CommonModule,
    DragulaModule.forRoot(),
    TranslateModule,
    UiModule,
    DirectivesModule.forRoot(environment),
    PipesModule,
    NgbModule,
    MapsModule.forRoot(environment),
    CloudModule,
  ],
  exports: [
    TranslateModule,
    DragulaModule,
    UiModule,
    DirectivesModule,
    PipesModule,
    NgbModule,
    MapsModule,
    NgxPermissionsModule,
    CloudModule,
  ],
})
export class SharedModule {}
