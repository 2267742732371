import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { TopbarComponent } from './components/topbar/topbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SharedModule } from '../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { LayoutTopbarComponent } from './layout-topbar/layout-topbar.component';

@NgModule({
  declarations: [LayoutTopbarComponent, TopbarComponent, FooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    SweetAlert2Module.forRoot(),
  ],
  exports: [FooterComponent],
})
export class LayoutsModule {}
