import { FullScreen } from 'ol/control';
import { Feature, Map, MapBrowserEvent } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import { Draw, Modify, Select, Translate } from 'ol/interaction';
import Projection from 'ol/proj/Projection';
import VectorSource from 'ol/source/Vector';
import {
  FeatureProperties,
  formatArea,
  formatLength,
  getMapStyle,
  ICQLFilter,
  IMapOptions,
  IMapSurveyLayer,
  IMapSurveyVector,
  IMapVector,
  IPopupInfo,
  IRelation,
  IStyleCondition,
  MapStyleSource,
  MapType,
  PopupOverlay,
  toDMS,
} from '@maplix/utils';
import VectorImageLayer from 'ol/layer/VectorImage';
import { cloneDeep, first } from 'lodash';
import { Cluster } from 'ol/source';
import { Heatmap, Layer } from 'ol/layer';
import GeoJSON from 'ol/format/GeoJSON';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import { ISurveyAnswer } from '@maplix/utils';
import { Geometry, MultiLineString, Point, Polygon } from 'ol/geom';
import { transform } from 'ol/proj';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Coordinate } from 'ol/coordinate';
import { getLength } from 'ol/sphere';

export class MapClsGeneric {
  public map: Map;

  // draw/select layers
  public lDraw: VectorLayer<VectorSource>;
  public lSelect: VectorLayer<VectorSource>;

  // draw interaction
  public iDraw: Draw;
  public isDrawing: boolean;
  public isModifying: boolean;

  //modify interaction
  public iModify: Modify;
  public iTranslate: Translate;

  //select interaction
  public iSelect: Select;
  public isSelecting: boolean;

  public language: string;

  protected projection: Projection;

  protected isTouchScreen: boolean = matchMedia('(pointer:coarse)').matches;

  private selectedFeatures: { feature: Feature; layer: Layer }[] = [];

  public popupOverlay: PopupOverlay;

  public popupInfo: IPopupInfo;

  public mapType: MapType;

  protected center: BehaviorSubject<Coordinate> = new BehaviorSubject<Coordinate>(undefined);
  public center$: Observable<Coordinate> = this.center.asObservable();

  protected zoom: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  public zoom$: Observable<number> = this.zoom.asObservable();

  constructor(
    public target: string,
    protected readonly translate: TranslateService,
    protected readonly http: HttpClient,
    protected readonly environment: any
  ) {}

  public addFullScreenControl(elementId: string = 'fullscreenBox') {
    this.map.addControl(
      new FullScreen({
        source: elementId,
      })
    );
  }

  public setMapOptions(options: IMapOptions) {
    if (!options) {
      return;
    }

    if (options.minZoom || options.minZoom === 0) {
      this.map.getView().setMinZoom(options.minZoom);
    }
    if (options.maxZoom || options.maxZoom === 0) {
      this.map.getView().setMaxZoom(options.maxZoom);
    }
  }

  public setCenter(center: Coordinate) {
    this.map.getView().setCenter(center);
    this.center.next(center);
  }

  public setZoom(zoom: number) {
    this.map.getView().setZoom(zoom);
    this.zoom.next(zoom);
  }

  public removeLastPoint() {
    this.iDraw.removeLastPoint();
  }

  public removeLastFeature(geomId: string) {
    const features = this.lDraw.getSource().getFeatures();
    if (features.length) {
      const lastFeature = features[features.length - 1];
      if (lastFeature.get('geomId') === geomId) {
        this.lDraw.getSource().removeFeature(lastFeature);
      }
    }
  }

  public setLanguage(language: string) {
    this.language = language;
  }

  public addWFS(
    config: IMapVector | IMapSurveyVector,
    styleSource: MapStyleSource,
    surveyLayer: IMapSurveyLayer | IMapVector,
    styleCondition?: IStyleCondition,
    cqlFilters?: ICQLFilter[],
    zIndex: number = 30000,
    crs: number = 3857
  ): VectorImageLayer<VectorSource> | Heatmap {
    let layer: VectorImageLayer<VectorSource> | Heatmap;
    let source: VectorSource;

    if (config.type === 'UPLOAD') {
      source = new VectorSource({
        format: new GeoJSON(),
        url: `${config.url.split('?')[0]}?service=WFS&version=1.3.0&request=GetFeature&typename=${
          config.typeName
        }&outputFormat=application/json&srsname=EPSG:${crs}`,
      });
    } else {
      source = new VectorSource({
        format: new GeoJSON(),
        url: (extent) => {
          return `${config.url.split('?')[0]}?service=WFS&version=1.3.0&request=GetFeature&typename=${
            config.typeName
          }&outputFormat=application/json&srsname=EPSG:${crs}&cql_filter=bbox(geometry,${extent.join(
            ','
          )})${this.parseCQL(cqlFilters)}&viewParams=${this.parseViewParams(styleCondition && styleCondition.rules)}`;
        },
        strategy: bboxStrategy,
      });
    }

    if (styleSource === 'heatmap' && surveyLayer.geomType === 'Point') {
      layer = new Heatmap({
        source: source as VectorSource<Point>,
        radius: styleCondition
          ? styleCondition.style.heatmapBlurRadius
          : first(surveyLayer.styleConditions).style.heatmapBlurRadius,
        blur: styleCondition
          ? styleCondition.style.heatmapBlurSize
          : first(surveyLayer.styleConditions).style.heatmapBlurSize,
      });
    } else if (styleSource === 'cluster') {
      layer = new VectorImageLayer({
        source: new Cluster({
          source,
          distance: styleCondition
            ? styleCondition.style.clusterDistance
            : first(surveyLayer.styleConditions).style.clusterDistance,
        }),
        declutter: true,
      });
    } else {
      layer = new VectorImageLayer({
        source,
      });
    }

    layer.setZIndex(zIndex);
    layer.setVisible(styleCondition ? styleCondition.selectedInMap : surveyLayer.selectedInMap);
    layer.setMinZoom(config.minZoom ? config.minZoom : 0);
    layer.setMaxZoom(config.maxZoom ? config.maxZoom : 22);
    layer.set('name', styleCondition ? styleCondition.title : surveyLayer.title);
    layer.set('maplixId', styleCondition ? styleCondition.id : surveyLayer.id);
    layer.set('selectable', config.isSelectable);
    layer.set('inLegend', config.showOnLegend);

    const layerStyle = getMapStyle(
      config.geomType === 'LineString' && (config as IMapSurveyVector).lineType === 'Route' ? 'Route' : config.geomType,
      styleCondition ? [styleCondition] : surveyLayer.styleConditions,
      styleSource,
      this.map
    );
    layer.setStyle(layerStyle);

    return layer;
  }

  private parseCQL(cqlFilters: ICQLFilter[]) {
    if (cqlFilters) {
      let result = cqlFilters.length ? ' AND ' : '';
      cqlFilters.forEach((cql, index, arr) => {
        result += `${cql.property}${cql.operator}${cql.value} ${
          index < arr.length - 1 ? (cql.groupOperator ? cql.groupOperator : 'AND') : ''
        } `;
      });
      return result;
    }
    return '';
  }

  private parseViewParams(relations: IRelation[]) {
    let i = 1;
    let result = '';
    if (!relations || !Array.isArray(relations)) {
      return result;
    }

    const applicableRelations = relations.slice(0, 3);
    applicableRelations.forEach((relation) => {
      if (relation.type === 'question' && relation.questionid) {
        result += `q${i}:${relation.questionid};`;
      }
      if (relation.subquestionid) {
        result += `sq${i}:${relation.subquestionid};`;
      }
      result += `t${i}:${relation.type === 'question' ? 'Q' : 'TM'};`;
      result += `o${i}:${this.mapOperator(relation.operator)};`;
      result += `v${i}:${relation.value};`;
      result += this.mapSelected(relation.operator, i);

      if (i < applicableRelations.length) {
        result += `go${i}:${relation.groupOperator};`;
      }

      i++;
    });

    return result;
  }

  private mapOperator(operator: string) {
    switch (operator) {
      case 'equals':
      case 'selected':
      case 'not-selected':
      case 'dropdown-equals':
      case 'not-dropdown-equal':
        return '=';
      case 'not-equal':
        return '<>';
      case 'greater-than-or-equals':
        return '>=';
      case 'smaller-than-or-equals':
        return '<=';
      case 'greater-than':
        return '>';
      case 'smaller-than':
        return '<';
    }
  }

  private mapSelected(operator: string, i: number) {
    switch (operator) {
      case 'selected':
      case 'dropdown-equals':
        return `sel${i}:true;`;
      case 'not-selected':
      case 'dropdown-not-equal':
        return `sel${i}:false;`;
      default:
        return '';
    }
  }

  setTarget(target: string) {
    this.target = target;
    this.map.setTarget(target);
  }

  public initPopup(element: HTMLElement) {
    this.popupOverlay = new PopupOverlay(element);
    this.map.addOverlay(this.popupOverlay);
  }

  protected async onClickMap(e: MapBrowserEvent<UIEvent>) {
    this.popupInfo = {};
    const features: { feature: Feature; layer: Layer }[] = [];
    this.map.forEachFeatureAtPixel(
      e.pixel,
      (f, layer) => {
        if (layer.get('selectable') && layer.get('visible') && !layer.get('base') && !(layer instanceof Heatmap)) {
          if (!features.map((feature) => feature.feature.getId()).includes(f.getId())) {
            features.push({ feature: f as Feature, layer: layer });
          }
        }
      },
      { hitTolerance: 4 }
    );

    if (features.length) {
      this.selectedFeatures = cloneDeep(features);
      if (!this.popupOverlay) {
        return;
      }

      this.popupOverlay.show(e.coordinate);

      if (this.selectedFeatures.length > 1) {
        this.popupInfo.title = this.translate.instant('map.popup.MULTIPLESELECTED');
        const features = [];
        this.selectedFeatures.forEach(async (feature) => {
          const propertiesObj = feature.feature.get('properties') ?? feature.feature.getProperties();
          let properties = propertiesObj;
          if (typeof propertiesObj === 'string') {
            properties = JSON.parse(propertiesObj);
          }

          const answers = await this.mapFeatureProperties(feature, properties);
          features.push(answers);
        });
        this.popupInfo = {
          ...this.popupInfo,
          features,
        };
      } else {
        const feature = first(this.selectedFeatures);
        const propertiesObj = feature.feature.get('properties') ?? feature.feature.getProperties();
        let properties = propertiesObj;
        if (typeof propertiesObj === 'string') {
          properties = JSON.parse(propertiesObj);
        }

        this.popupInfo.title =
          feature.layer.get('name') &&
          feature.layer.get('name').find((name) => name.i18n === this.translate.currentLang)?.value;

        const answers = await this.mapFeatureProperties(feature, properties);
        this.popupInfo = {
          ...this.popupInfo,
          features: [answers],
        };
      }
    } else {
      this.selectedFeatures = [];
      if (this.popupOverlay) {
        this.popupOverlay.hide();
      }
    }
  }

  private async mapFeatureProperties(
    feature: { feature: Feature; layer: Layer },
    properties: any
  ): Promise<FeatureProperties> {
    let answers: ISurveyAnswer[];
    if (feature.feature.get('survey_id')) {
      answers = await this.http
        .get<any>(
          `${this.environment.api}surveys/${feature.feature.get('survey_id')}/responses/${feature.feature.get(
            'response_id'
          )}/answers`
        )
        .pipe(map((response) => (response?.length ? response[0].answers : [])))
        .toPromise();
    }

    const geometry = feature.feature.getGeometry();
    return {
      accordionId: `${feature.feature.getId()}`.replace(/\./g, '_'),
      accordionLabel:
        feature.layer.get('name') &&
        feature.layer.get('name').find((name) => name.i18n === this.translate.currentLang)?.value,
      transportMode: properties.transportMode,
      length: this.mapType === MapType.IMAGE ? undefined : this.getFeatureLength(geometry),
      area: this.mapType === MapType.IMAGE ? undefined : this.getFeatureArea(geometry),
      answers: !answers
        ? undefined
        : this.groupAnswersPerQuestion(answers, feature.feature.get('interaction'), feature.feature.get('survey_id')),
      coordinates: this.mapType === MapType.IMAGE ? undefined : this.getFeatureCoordinates(geometry),
      properties: feature.feature.getProperties(),
    };
  }

  private getFeatureLength(geometry: Geometry) {
    return geometry.getType() === 'LineString'
      ? formatLength(getLength(geometry, { projection: 'EPSG:3857' }))
      : geometry.getType() === 'MultiLineString'
      ? formatLength(
          (geometry as MultiLineString)
            .getLineStrings()
            .map((line) => getLength(line, { projection: 'EPSG:3857' }))
            .reduce((prev, current) => prev + current)
        )
      : undefined;
  }

  private getFeatureArea(geometry: Geometry) {
    return geometry.getType() === 'Polygon' ? formatArea((geometry as Polygon).getArea()) : undefined;
  }

  private getFeatureCoordinates(geometry: Geometry) {
    return geometry.getType() === 'Point'
      ? toDMS(transform((geometry as Point).getCoordinates(), 'EPSG:3857', 'EPSG:4326'))
      : undefined;
  }

  private groupAnswersPerQuestion(
    answers: ISurveyAnswer[],
    interaction: string,
    surveyId: string
  ): { [key: string]: ISurveyAnswer[] } {
    const grouped = {};
    answers
      .filter((answer) =>
        typeof answer.value_selected === 'boolean'
          ? answer.value_selected
          : answer.value || answer.value_order || answer.value_other
      )
      .filter((answer) => !answer.interaction || answer.interaction === interaction || answer.survey_id !== surveyId)
      .forEach((answer) => {
        if (!grouped[answer.question]) {
          grouped[answer.question] = [answer];
        } else {
          grouped[answer.question].push(answer);
        }
      });
    return grouped;
  }
}
