import { Component, OnInit } from '@angular/core';
import { PlausibleService, WorkspaceService } from '@maplix/services';
import { THEME_COLORS } from '@maplix/utils';
import * as Color from 'color';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { SharedService } from './shared/services/shared.service';
import { ApiService } from '@maplix/api';
import { CookieService } from 'ngx-cookie-service';
import { RealmService } from './shared/services/realm.service';
import { takeUntil } from 'rxjs/operators';
import { DestroyComponent } from '@maplix/ng-utils';

@Component({
  selector: 'engage-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends DestroyComponent implements OnInit {
  public cookieDomain: string = environment.cookieDomain;

  public isResponseModule$: Observable<boolean> = this.shared.isResponseModule$;

  constructor(
    private shared: SharedService,
    private plausible: PlausibleService,
    private api: ApiService,
    private cookie: CookieService,
    private workspaceService: WorkspaceService,
    private realmService: RealmService
  ) {
    super();
    this.plausible.initTracker();
  }

  ngOnInit(): void {
    const activeWorkspaceJSON = this.cookie.get(`activeWorkspace${environment.env}`);
    const activeWorkspace = activeWorkspaceJSON ? JSON.parse(activeWorkspaceJSON) : null;
    if (activeWorkspace) {
      this.workspaceService.setActiveWorkspace(activeWorkspace);
      this.api.organisation
        .getOrganisationById()
        .pipe(takeUntil(this.destroyed))
        .subscribe(
          (response) => {
            this.workspaceService.setActiveWorkspace(response);
            if (response) {
              this.cookie.set(
                `activeWorkspace${environment.env}`,
                JSON.stringify(response),
                30,
                '/',
                environment.cookieDomain
              );
            } else {
              this.realmService.auth.logout();
            }
          },
          () => {
            this.realmService.auth.logout();
          }
        );
    }

    if (this.cookie.get(`MaplixToken${environment.env}`)) {
      this.realmService.auth.checkApiKey(this.cookie.get(`MaplixToken${environment.env}`));
    }

    const userDetailsJSON = this.cookie.get(`userDetails${environment.env}`);
    const userDetails = userDetailsJSON ? JSON.parse(userDetailsJSON) : null;

    if (userDetails) {
      this.api.user
        .getUserById(userDetails._id)
        .pipe(takeUntil(this.destroyed))
        .subscribe((response) => {
          this.workspaceService.setUserDetails(response);
          if (response) {
            this.cookie.set(
              `userDetails${environment.env}`,
              JSON.stringify(response),
              30,
              '/',
              environment.cookieDomain
            );
          }
        });
    }

    const css = `
    .btn-explore-primary {
      background-color: ${THEME_COLORS.EXPLORE.PRIMARY};
      border-color: ${THEME_COLORS.EXPLORE.PRIMARY};
    }
    .btn-explore-primary:hover {
      background-color: ${Color(THEME_COLORS.EXPLORE.PRIMARY).darken(0.15)};
      color: #FFFFFF;
    }
    .bg-soft-explore-primary {
      background-color: ${Color(THEME_COLORS.EXPLORE.PRIMARY).alpha(0.15)};
    }
    `;

    const head = document.head;
    const style = document.createElement('style');
    head.appendChild(style);

    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
  }
}
