import { Map, View } from 'ol';
import { defaults as DefaultInteractions } from 'ol/interaction.js';
import Projection from 'ol/proj/Projection';
import { Tile as TileLayer, Vector as VectorLayer, Image as ImageLayer } from 'ol/layer.js';
import ImageWMS from 'ol/source/ImageWMS';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector.js';
import { Icon, Style, Stroke, Fill, Circle } from 'ol/style';
import XYZ from 'ol/source/XYZ';
import * as styleUtils from '@maplix/utils';
import { MapSettingsService } from '../services';
import { IMapVector, IMapWMS, IMapWMSLayer, THEME_COLORS } from '@maplix/utils';
import { range } from 'lodash';
import { Coordinate } from 'ol/coordinate';
import { BehaviorSubject, Observable } from 'rxjs';
import { MapClsGeneric } from './mapcls-generic';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ScaleLine, Zoom } from 'ol/control';

export interface ILegendUrl {
  id: string;
  title: string;
  legends: any[];
  visible: boolean;
}

export class MapCls extends MapClsGeneric {
  public lDrawRoute: VectorLayer<VectorSource>;
  public lMarker: VectorLayer<VectorSource>;

  public WMSLegendUrls: ILegendUrl[] = [];

  constructor(
    public target: string,
    translate: TranslateService,
    http: HttpClient,
    environment: any,
    center?: Coordinate,
    zoom?: number,
    private isStatic: boolean = false
  ) {
    super(target, translate, http, environment);
    this.init(center, zoom);
    this.addBaseLayers();
  }

  init(center: Coordinate = [0, 0], zoom: number = 2) {
    const controls = this.isStatic ? [] : [new Zoom(), new ScaleLine()];

    this.map = new Map({
      target: this.target,
      layers: [],
      interactions: DefaultInteractions({
        doubleClickZoom: this.isStatic ? false : true,
        mouseWheelZoom: this.isStatic ? false : true,
        dragPan: this.isStatic ? false : true,
      }),
      view: new View({
        projection: new Projection({
          code: 'EPSG:3857',
          units: 'm',
        }),
        center,
        zoom,
        maxZoom: 22,
      }),
      controls,
      moveTolerance: this.isTouchScreen ? 6 : 1,
    });

    this.center.next(center);
    this.zoom.next(zoom);

    this.map.getView().on('change', () => {
      this.center.next(this.map.getView().getCenter());
      this.zoom.next(this.map.getView().getZoom());
    });
  }

  addBaseLayers() {
    const layers = [
      new TileLayer({
        source: new XYZ({
          url: `https://api.mapbox.com/styles/v1/cedricversluys/ckficsqxq0oq819nvh307xwy5/tiles/256/{z}/{x}/{y}?access_token=${this.environment.mapboxToken}`,
          crossOrigin: 'anonymous',
        }),
        visible: true,
      }),
      new TileLayer({
        source: new XYZ({
          url: `https://api.mapbox.com/styles/v1/cedricversluys/cki7ida3w0ok919pbekhoqmbp/tiles/256/{z}/{x}/{y}?access_token=${this.environment.mapboxToken}`,
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      new TileLayer({
        source: new XYZ({
          url: `https://api.mapbox.com/styles/v1/cedricversluys/cklgmfd9g12y617qsbp9d5m8p/tiles/256/{z}/{x}/{y}?access_token=${this.environment.mapboxToken}`,
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      new TileLayer({
        source: new XYZ({
          url: `https://api.mapbox.com/styles/v1/cedricversluys/cksgibkcxnswk17pl3xqoijse/tiles/256/{z}/{x}/{y}?access_token=${this.environment.mapboxToken}`,
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      new TileLayer({
        source: new XYZ({
          url: `https://api.mapbox.com/styles/v1/cedricversluys/ckficu3vp0lpb19s2eod7eitn/tiles/256/{z}/{x}/{y}?access_token=${this.environment.mapboxToken}`,
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      new TileLayer({
        source: new XYZ({
          url: `https://api.mapbox.com/styles/v1/cedricversluys/ckpy1e3970mdb18rlrp3ep0w8/tiles/256/{z}/{x}/{y}?access_token=${this.environment.mapboxToken}`,
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      new TileLayer({
        source: new XYZ({
          url: `https://api.mapbox.com/styles/v1/cedricversluys/ckficuixz09dk19mljp7j9slc/tiles/256/{z}/{x}/{y}?access_token=${this.environment.mapboxToken}`,
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
    ];

    layers[0].set('name', 'mapbox-basic');
    layers[1].set('name', 'mapbox-streets');
    layers[2].set('name', 'mapbox-outdoors');
    layers[3].set('name', 'mapbox-spring');
    layers[4].set('name', 'mapbox-light');
    layers[5].set('name', 'mapbox-dark');
    layers[6].set('name', 'mapbox-satellite');

    layers.forEach((l) => {
      l.set('base', true);
      this.map.addLayer(l);
    });
  }

  addDrawLayer() {
    this.lDraw = new VectorLayer({
      source: new VectorSource({}),
      style: (f) => {
        return styleUtils.drawStyle(f);
      },
      zIndex: 40000,
    });

    this.lDraw.set('base', false);
    this.lDraw.set('name', 'lDraw');

    this.lDrawRoute = new VectorLayer({
      source: new VectorSource({}),
      style: (feature) => {
        const bg = new Style({
          image: new Circle({
            radius: feature.get('tmIcon') ? 10 : 9,
            stroke: new Stroke({
              color:
                feature.get('drawStyle')?.lineBorderWidth >= 1
                  ? feature.get('drawStyle')?.lineBorderColor
                  : feature.get('drawStyle')?.color,
              width: 2,
            }),
            fill: new Fill({
              color: feature.get('tmIcon') ? 'white' : feature.get('drawStyle')?.color,
            }),
          }),
        });

        const icon = new Style({
          image: new Icon({
            crossOrigin: 'anonymous',
            src: `assets/images/map/${feature.get('tmIcon')}.png`,
            scale: 0.14,
            rotateWithView: true,
            color: THEME_COLORS.GENERIC.FONT,
          }),
        });

        return feature.get('tmIcon') ? [bg, icon] : bg;
      },
      zIndex: 40000,
    });

    this.lDrawRoute.set('base', false);
    this.lDrawRoute.set('name', 'lDrawRoute');

    this.lMarker = new VectorLayer({
      source: new VectorSource({}),
      style: new Style({
        image: new Icon({
          src: 'assets/images/map/marker-2.png',
          anchor: [0.5, 1],
          scale: 0.05,
        }),
      }),
      zIndex: 30000,
    });

    this.lMarker.set('base', false);
    this.lMarker.set('name', 'lMarker');

    this.map.addLayer(this.lDraw);
    this.map.addLayer(this.lDrawRoute);
    this.map.addLayer(this.lMarker);
  }

  public addWMS(config: IMapWMS, zIndex: number, layer?: IMapWMSLayer): TileLayer<TileWMS> {
    const wmsLayer = new TileLayer({
      source: new TileWMS({
        url: config.url,
        params: {
          FORMAT: 'image/png',
          CRS: '3857',
          LAYERS: layer
            ? [layer.name]
            : (config as IMapWMS).layers
            ? (config as IMapWMS).layers
                .filter((layer) => layer.selectedInModal && layer.name)
                .map((layer) => layer.name)
            : '',
        },
        crossOrigin: 'anonymous',
      }),
      visible: layer ? layer.selectedInMap : config.selectedInMap,
      minZoom: config.minZoom ? config.minZoom : 0,
      maxZoom: config.maxZoom ? config.maxZoom : 22,
      opacity: (config as IMapWMS).opacity || (config as IMapWMS).opacity === 0 ? (config as IMapWMS).opacity / 100 : 1,
    });

    wmsLayer.setZIndex(zIndex);
    wmsLayer.set('name', config.title);
    wmsLayer.set('maplixId', layer ? layer.id : config.id);

    this.getWMSLegend(config, layer);

    return wmsLayer;
  }

  protected getWMSLegend(config: IMapWMS, layer: IMapWMSLayer) {
    const legends = [];
    legends.push({
      url:
        config.url.split('?')[0] +
        '?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image/png&LAYER=' +
        layer.name,
      name: layer.name,
    });

    const urlObj: ILegendUrl = {
      id: layer.id,
      title: layer.title as string,
      legends: legends,
      visible: false,
    };

    this.WMSLegendUrls = this.WMSLegendUrls.filter((url) => url.id !== urlObj.id);
    this.WMSLegendUrls.push(urlObj);
  }

  public getZoom() {
    return this.zoom.value;
  }

  public getCenter() {
    return this.center.value;
  }
}
